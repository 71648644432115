import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { Dimmer, Grid, Loader, Segment, Header, Image, Button } from "semantic-ui-react";
import CourseSummaryDto from "../../models/Courses/courseSummaryDto";
import { useStore } from "../../stores/store";

export interface Props {
  courseId: string;
  course: CourseSummaryDto | undefined;
}

export default function CourseInfo({ courseId, course }: Props) {
  const { t } = useTranslation("common");
  const { lookupsStore, commonStore, userStore } = useStore();
  const [inProgress, setInProgress] = useState<boolean>(true);

  useEffect(() => {
    if (!course) setInProgress(true);
    else setInProgress(false);
  }, [course]);

  const loader = (
    <Segment className="injaz-loader-tbl">
      <Dimmer active inverted>
        <Loader size="large">{t("Common.Loading")} ...</Loader>
      </Dimmer>
    </Segment>
  );

  const content = (
    <>
      <Grid className="injaz-course-details" stackable>
        <Grid.Column width={3}>
          <Image
            src={
              course?.imageUrl == null || course?.imageUrl === ""
                ? "/img/default_course.png"
                : course?.imageUrl
            }
          />
        </Grid.Column>
        <Grid.Column width={10}>
          <Header as="h3" className="injaz-course-name">
            {t("CourseManagement.CourseName")}: {course?.name}
          </Header>
          <div className="injaz-course-inpersononline">
            <span>{course?.inPerson === true ? t("Common.InPerson") : t("Common.Online")}</span>
          </div>
          <div className="injaz-course-startenddate">
            <span className="injaz-course-start">{t("CourseManagement.StartDate")} </span>
            <span>{course?.startDate}</span>
            <span className="injaz-course-end">{t("CourseManagement.EndDate")}: </span>
            <span>{course?.endDate}</span>
          </div>
          <div className="injaz-course-inpersononline">
            {t("CourseManagement.MinimumNumberOfSessions")}:
            <span>{course?.minimumNumberOfSessions}</span>
          </div>
          <div className="injaz-course-inpersononline">
            {t("ProgramManagement.NumberofSessions")}: <span>{course?.numberOfSessions}</span>
          </div>
          <div className="injaz-course-days">
            {t("CourseManagement.Days")}:
            {course?.implementationDays == null || course?.implementationDays.length === 0 ? "-" : ""}
            {course?.implementationDays != null &&
              course?.implementationDays.length > 0 &&
              course?.implementationDays
                .sort()
                .map((day) => (
                  <span key={day}>{lookupsStore.getWeekdayValue(day, commonStore.lang)} </span>
                ))}
          </div>
          <div className="injaz-course-year">
            {t("CourseManagement.AcademicYear")}: <span>{course?.academicYear}</span>
          </div>
          <div className="injaz-course-year">
            {t("CourseManagement.TeacherName")}: <span>{course?.teacherName}</span>
          </div>
          <div className="injaz-course-injazemployee">
            {t("CourseManagement.PublishedOn")}:{" "}
            <span>{course?.publishDate === null ? "-" : course?.publishDate}</span>
          </div>
          <div className="injaz-course-injazemployee">
            {t("CourseManagement.CreatedBy")}: <span>{course?.creator}</span>
          </div>
        </Grid.Column>
        <Grid.Column width={3}>
          <div>
            {userStore.isAdmin && (
              <a
                className="ui blue basic button course-btn"
                role="button"
                href={course?.edxStudioCourseUrl}
                target="_blank"
                rel="noreferrer"
              >
                {t("CourseManagement.EditCourseContent")}
              </a>
            )}
          </div>
          {userStore.isAdmin && (
            <div>
              <Button
                className="course-btn"
                basic
                color="blue"
                disabled={course?.isPublished}
                content={t("CourseManagement.EditCourseDetails")}
                as={NavLink}
                to={`/editcourse/${courseId}`}
              />
            </div>
          )}
          <div>
            {userStore.isAdmin && (
              <a
                className="ui blue basic button course-btn"
                role="button"
                href={`https://studio.injaz.edu.jo/course_team/${course?.adoptCourseId}`}
                target="_blank"
                rel="noreferrer"
              >
                {t("ProgramManagement.AddAnotherAdmin")}
              </a>
            )}
          </div>
        </Grid.Column>
      </Grid>
    </>
  );

  return (
    <>
      {inProgress && loader}
      {!inProgress && content}
    </>
  );
}

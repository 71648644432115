import React, { useEffect, useState } from "react";
import { Button, Container, Form, Header } from "semantic-ui-react";
import { SessionDto } from "../../models/sessionDto";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useStore } from "../../stores/store";
import agent from "../../api/agent";
import { Formik } from "formik";
import CustomTextInput from "../../common/forms/CustomTextInput";
import CustomTextAreaInput from "../../common/forms/CustomTextAreaInput";
import CustomSelectInput from "../../common/forms/CustomSelectInput";
import CustomDateInput from "../../common/forms/CustomDateInput";
import CustomCheckboxInput from "../../common/forms/CustomCheckboxInput";
import { toast } from "react-toastify";

export interface Props {
  onClose: () => void;
  onSessionAdded: () => void;
  session: SessionDto | undefined;
}

export default function AddEditSession({ onClose, onSessionAdded, session }: Props) {
  const { t } = useTranslation("common");
  const { lookupsStore, commonStore } = useStore();
  const [isSaving, setIsSaving] = useState(false);
  const [times, setTimes] = useState<{ text: string; value: number }[]>([]);
  const [volunteers, setVolunteers] = useState<{ text: string; value: number }[]>([]);

  const handleError = (error: any) => toast.error(t("Common.GeneralErrorMessage"));

  useEffect(() => {
    let timesList = lookupsStore.getCourseTimes(commonStore.lang);
    setTimes(timesList);
  }, [commonStore.lang]);

  useEffect(() => {
    if (session == null) return;
    agent.Lookups.courseVolunters(session.courseId)
      .then((result) => setVolunteers(result))
      .catch((error) => handleError(error));
  }, [session]);

  const validationSchema = Yup.object({
    name: Yup.string().required(t("Common.Required")),
    duration: Yup.number()
      .typeError(t("Common.InvalidNumber"))
      .min(1, t("CourseManagement.InValidDuration"))
      .required(t("Common.Required")),
    description: Yup.string().required(t("Common.Required")),
    status: Yup.string().required(t("Common.Required")),
    sessionDate: Yup.date()
      .max("2050-01-01", t("Common.InvalidDate"))
      //.min(new Date().toDateString(), t("Common.InvalidDate"))
      .required(t("Common.Required")),
    sessionTime: Yup.string().required(t("Common.Required")),
    volunteerId: Yup.string().required(t("Common.Required")),
  });

  const submitForm = (values: SessionDto) => {
    setIsSaving(true);
    if (session?.id === 0) {
      //add new session
      agent.Sessions.add(values)
        .then((result) => {
          onSessionAdded();
          setIsSaving(false);
          onClose();
        })
        .catch((error) => handleError(error))
        .finally(() => {
          setIsSaving(false);
        });
    } else {
      //updated session
      agent.Sessions.update(values)
        .then((result) => {
          onSessionAdded();
          setIsSaving(false);
          onClose();
        })
        .catch((error) => handleError(error))
        .finally(() => {
          setIsSaving(false);
        });
    }
  };

  return (
    <>
      <Container>
        <Formik
          validationSchema={validationSchema}
          enableReinitialize={true}
          initialValues={session!}
          onSubmit={(values) => submitForm(values)}
        >
          {({ handleSubmit }) => (
            <Form onSubmit={handleSubmit} autoComplete="off">
              <Header as="h2">
                {t("CourseManagement.SessionNumber")}: {session?.sessionNumber}
              </Header>
              <CustomTextInput
                label={t("CourseManagement.SessionName")}
                placeholder={t("CourseManagement.SessionName")}
                name="name"
                autoComplete="new-password"
              />
              <CustomTextInput
                label={t("CourseManagement.Duration") + " (" + t("CourseManagement.Hours") + ")"}
                placeholder={t("CourseManagement.Duration")}
                name="duration"
              />
              <div className="ui labeled label">{t("CourseManagement.Description")}</div>
              <CustomTextAreaInput
                label={t("CourseManagement.Description")}
                placeholder={t("CourseManagement.Description")}
                name="description"
                rows={5}
              />
              <CustomSelectInput
                label={t("CourseManagement.Status")}
                placeholder={t("CourseManagement.Status")}
                name="status"
                options={[
                  { text: t("CourseManagement.NotStarted"), value: 1, key: 1 },
                  { text: t("CourseManagement.Closed"), value: 2, key: 2 },
                ]}
              />
              <label>{t("CourseManagement.Date")}</label>
              <CustomDateInput label={t("CourseManagement.Date")} name="sessionDate" />
              <CustomSelectInput
                label={t("CourseManagement.Time")}
                placeholder={t("CourseManagement.Time")}
                name="sessionTime"
                options={times}
              />
              <CustomSelectInput
                label={t("CourseManagement.Volunteer")}
                placeholder={t("CourseManagement.Volunteer")}
                name="volunteerId"
                options={volunteers}
              />
              <CustomCheckboxInput name="inPerson" label={t("Common.InPerson")} type="checkbox" />
              <Button type="submit" color="blue" content={t("Common.Save")} loading={isSaving} />
            </Form>
          )}
        </Formik>
        {!isSaving && (
          <Button className="addeditsessions-close-btn" onClick={() => onClose()}>
            {t("Common.Close")}
          </Button>
        )}
      </Container>
    </>
  );
}
